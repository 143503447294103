.leaflet-container {
    height: 100vh;
    min-height: 600px;
    width: 100%;
}

.leaflet-div-icon {
    background: #ffa500;
    border-radius: 50%;
}
